import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'

import { createBotQuickTopic } from '../../utils/assistantHelper'
import AssistantAvatar from '../AdminPanel/Assistants/AssistantAvatar'

export default function AssistantQuickTopic({ containerStyle }) {
    const selectedProjectIndex = useSelector(state => state.selectedProjectIndex)
    const defaultAssistant = useSelector(state => state.defaultAssistant)

    const createTopic = () => {
        createBotQuickTopic(defaultAssistant)
    }

    return (
        <TouchableOpacity style={[localStyles.container, containerStyle]} onPress={createTopic} accessible={false}>
            <AssistantAvatar photoURL={defaultAssistant.photoURL} assistantId={defaultAssistant.uid} size={24} />
        </TouchableOpacity>
    )
}

const localStyles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 28,
        width: 28,
    },
})
