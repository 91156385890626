import React from 'react'
import { useDispatch } from 'react-redux'

import { setSelectedNavItem } from '../../../../redux/actions'
import AssistantAvatar from '../../../AdminPanel/Assistants/AssistantAvatar'
import { TouchableOpacity } from 'react-native'
import NavigationService from '../../../../utils/NavigationService'
import { DV_TAB_PROJECT_ASSISTANTS } from '../../../../utils/TabNavigationConstants'

export default function AssistantAvatarButton({ assistant, projectIndex }) {
    const dispatch = useDispatch()

    const navigateToProjectAssistants = () => {
        NavigationService.navigate('ProjectDetailedView', {
            projectIndex,
        })
        dispatch(setSelectedNavItem(DV_TAB_PROJECT_ASSISTANTS))
    }

    return (
        <TouchableOpacity onPress={navigateToProjectAssistants}>
            <AssistantAvatar photoURL={assistant.photoURL300} assistantId={assistant.uid} size={24} />
        </TouchableOpacity>
    )
}
