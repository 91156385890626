import React, { useRef, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { colors } from '../../../styles/global'
import { applyPopoverWidth, MODAL_MAX_HEIGHT_GAP } from '../../../../utils/HelperFunctions'
import useWindowSize from '../../../../utils/useWindowSize'
import CustomScrollView from '../../../UIControls/CustomScrollView'
import { translate } from '../../../../i18n/TranslationService'
import ModalHeader from '../ModalHeader'
import NameArea from './NameArea'
import PromptArea from './PromptArea'
import ButtonsArea from './ButtonsArea'
import VariablesArea from './VariablesArea'
import DropDown from './DropDown'
import LinkArea from './LinkArea'
import { REGEX_URL } from '../../../Feeds/Utils/HelperFunctions'

export const TASK_TYPE_PROMPT = 'prompt'
export const TASK_TYPE_EXTERNAL_LINK = 'link'

export default function TaskModal({
    closeModal,
    adding,
    name,
    setName,
    prompt,
    setPrompt,
    variables,
    openVariableModal,
    removeVariable,
    addTask,
    saveTask,
    deleteTask,
    disabled,
    promptInputRef,
    linkInputRef,
    link,
    setLink,
    taskType,
    setTaskType,
}) {
    const isMiddleScreen = useSelector(state => state.isMiddleScreen)
    const smallScreenNavigation = useSelector(state => state.smallScreenNavigation)

    const items = [
        { label: translate('Prompt'), value: TASK_TYPE_PROMPT, marginTop: 0 },
        {
            label: translate('External link'),
            value: TASK_TYPE_EXTERNAL_LINK,
        },
    ]

    const nameInputRef = useRef()

    const [width, height] = useWindowSize()

    const checkIfIsValidLink = () => {
        const cleanLink = link.trim()
        return REGEX_URL.test(cleanLink) && !/\s/.test(cleanLink)
    }

    const disableButton =
        !name.trim() ||
        (taskType === TASK_TYPE_PROMPT && !prompt.trim()) ||
        (taskType === TASK_TYPE_EXTERNAL_LINK && !checkIfIsValidLink())

    const onPressKey = event => {
        if (disabled) return
        if (event.key === 'Enter') {
            if (!disableButton && !event.shiftKey) {
                event.preventDefault()
                event.stopPropagation()
                adding ? addTask() : saveTask()
            }
        } else if (event.key === 'Tab') {
            event.preventDefault()
            event.stopPropagation()
            if (nameInputRef.current.isFocused()) {
                promptInputRef.current.focus()
            } else if (promptInputRef.current.isFocused()) {
                nameInputRef.current.focus()
            }
        }
    }

    useEffect(() => {
        setTimeout(() => nameInputRef.current.focus(), 1)
    }, [])

    useEffect(() => {
        document.addEventListener('keydown', onPressKey)
        return () => {
            document.removeEventListener('keydown', onPressKey)
        }
    })

    return (
        <View style={[localStyles.container, applyPopoverWidth(), { maxHeight: height - MODAL_MAX_HEIGHT_GAP }]}>
            <CustomScrollView style={localStyles.scroll} showsVerticalScrollIndicator={false}>
                <ModalHeader
                    title={translate(adding ? 'Add new task' : 'Update task')}
                    description={translate(
                        adding ? 'Enter the data to add the task' : 'Change the data to update the task'
                    )}
                    closeModal={closeModal}
                />
                <NameArea disabled={disabled} nameInputRef={nameInputRef} name={name} setName={setName} />
                <DropDown
                    items={items}
                    value={taskType}
                    setValue={setTaskType}
                    placeholder={translate('Choose the task type')}
                    header={translate('Task type')}
                    containerStyle={{ marginTop: 12 }}
                    disabled={disabled}
                    arrowStyle={{
                        position: 'absolute',
                        top: -32,
                        left: smallScreenNavigation ? 232 : isMiddleScreen ? 296 : 360,
                    }}
                />
                {taskType === TASK_TYPE_PROMPT ? (
                    <PromptArea
                        disabled={disabled}
                        promptInputRef={promptInputRef}
                        prompt={prompt}
                        setPrompt={setPrompt}
                    />
                ) : (
                    <LinkArea
                        disabled={disabled}
                        linkInputRef={linkInputRef}
                        link={link}
                        setLink={setLink}
                        isValid={checkIfIsValidLink()}
                    />
                )}

                {taskType === TASK_TYPE_PROMPT && (
                    <VariablesArea
                        disabled={disabled}
                        variables={variables}
                        openVariableModal={openVariableModal}
                        removeVariable={removeVariable}
                    />
                )}

                {!disabled && (
                    <ButtonsArea
                        adding={adding}
                        addTask={addTask}
                        saveTask={saveTask}
                        deleteTask={deleteTask}
                        disableButton={disableButton}
                    />
                )}
            </CustomScrollView>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        backgroundColor: colors.Secondary400,
        borderRadius: 4,
        width: 305,
        shadowColor: 'rgba(78, 93, 120, 0.56)',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 1,
        shadowRadius: 16,
        elevation: 3,
    },
    scroll: {
        paddingTop: 16,
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
    },
})
